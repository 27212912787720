export default [
    {
        id: 0,
        name: 'card',
        kor: '카드',
    },
    {
        id: 1,
        name: 'bank_account',
        kor: '무통장 입금',
    },
]
