<template>
    <div class="schedule-payment">
        <StackRouterHeaderBar :show-title="true" title="결제" />
        <div class="header" v-if="$store.getters.me.gender === 1">
            <p>결제가 완료되면</p>
            <p>약속이 확정됩니다!</p>
        </div>
        <div class="body">
            <section class="section m-b-32">
                <div class="title">약속 일정</div>
                <div class="grey-box">
                    <div class="user-profile">
                        <img :src="userInfo.photoUrl" alt="" />
                        <div class="profile-info">
                            <div class="age" v-html="userInfo.age" />
                            <div class="region" v-html="time" />
                            <div class="meet-type" v-html="meetType" />
                        </div>
                    </div>
                </div>
                <div v-if="openNextDay" class="open-next-day">
                    <img class="m-r-4" src="@/assets/images/icons/info_pink.png" width="16px" height="16px" />
                    <span>만남이 내일이므로 약속 확정시 바로 방이 생성됩니다!</span>
                </div>
            </section>
            <section class="section m-b-40">
                <div class="title">결제 상세</div>
                <div class="price-detail">
                    <div class="row">
                        <div class="key">매칭비용</div>
                        <div class="value" v-html="`${product.pre_price.toLocaleString()} 원`" />
                    </div>
                    <div class="row under-line">
                        <div class="key">이벤트 할인</div>
                        <div class="value" v-html="`-${product.discount.toLocaleString()} 원`" />
                    </div>
                    <div class="row m-t-8">
                        <div class="key c-black">최종 결제금액</div>
                        <div class="value">
                            <span class="c-primary spoqa-f-bold">{{ product.price.toLocaleString() }} </span>
                            <span>원</span>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="title">결제 수단 선택</div>
                <div class="payment-buttons grid-col">
                    <div
                        @click="clickPayMethod(item.id)"
                        class="btn btn-normal"
                        :class="{ inactive: item.id === 0, selected: selected === item.id }"
                        v-for="item in paymentMethods"
                        :key="item.id"
                        v-html="item.kor"
                    />
                </div>
            </section>
        </div>
        <BottomButton
            @click="clickSubmit"
            :disabled="disabled"
            :label="`${product.price.toLocaleString()}원 결제하기`"
            :black-btn="true"
        />
    </div>
</template>

<script>
import productService from '@/services/product'
import paymentMethods from '@/assets/constants/payments'
import scheduleService from '@/services/schedule'

export default {
    name: 'SchedulePayPage',
    props: {
        userInfo: Object,
    },
    data: () => ({
        // products: {
        //     pre_price: 100000,
        //     discount: 50000,
        //     price: 0,
        // },
        selected: null,
    }),
    methods: {
        clickPayMethod(id) {
            if (id === 0) {
                this.$toast.error('현재는 무통장 입금만 가능합니다')
                return
            }
            this.selected = id
        },
        async clickSubmit() {
            if (this.hasProduct) {
                const payload = { product_id: 1 }
                payload[`${this.$store.getters.me.gender === 1 ? 'a' : 'b'}_message_id`] = this.userInfo.message.id

                await scheduleService.createSchedule(this.userInfo.schedule_id, payload)
                this.$store.dispatch('loadChat', { chatId: this.userInfo.message.chat_id, force: true }).then(() => {
                    this.$toast.success('결제가 완료되었습니다!')
                })
                this.$store.dispatch('loadMe')
            } else {
                const idx = await this.$modal.basic({
                    body: '아래 계좌로 이체해주세요.<br>우리은행 1005-904-223345',
                    actionAlert: true,
                    buttons: [
                        {
                            label: '계좌번호 복사',
                            class: 'btn-primary',
                        },
                    ],
                })

                if (idx !== undefined) {
                    this.$copyText(`국민 12345-10-6789`).then(() => {
                        this.$modal.basic({
                            body: '계좌번호가 복사되었습니다.<br>이체 후 매니저에게 “결제 완료” 되었다고 알려주세요!',
                            buttons: [
                                {
                                    label: '네, 입금하겠습니다.',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                    })
                }
            }

            this.$stackRouter.pop()
        },
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        isNewbie() {
            // return true
            return this.me.contract_id === 2 || this.me.contract_id === 3
        },
        disabled() {
            if (this.hasProduct) return false

            return this.selected === null
        },
        hasProduct() {
            if (!this.isNewbie) return true

            const products = this.me.products
            return products.some(item => item.ptype === 'ticket' && item.enabled === true)
        },
        product() {
            return {
                pre_price: 100000,
                discount: this.isNewbie ? 50000 : 100000,
                price: this.isNewbie ? 50000 : 0,
            }
        },
        paymentMethods() {
            return paymentMethods
        },
        time() {
            const dateConfirmed = this.userInfo.date_confirmed
            const date = this.$moment(dateConfirmed).format('YY/MM/DD')
            const day = this.$moment(dateConfirmed).format('dddd').toUpperCase()
            const time = this.$moment(dateConfirmed).format('A h시 mm분')

            return `${date} ${this.$translate(day)}, ${time}`
        },
        meetType() {
            const fixed = ['coffee', 'meal']
            const meetPlace = this.userInfo.meet_place

            return fixed.includes(this.userInfo.meet_type_confirmed)
                ? `${meetPlace}에서 ${this.$translate(this.userInfo.meet_type_confirmed.toUpperCase())} 예정`
                : `${meetPlace}에서 커피 또는 식사 예정`
        },
        openNextDay() {
            const nextday = this.$moment().add(1, 'days')
            const day = this.$moment(this.userInfo.date_confirmed)
            return this.$moment(nextday).format('YY/MM/DD') === this.$moment(day).format('YY/MM/DD')
        },
    },
    async created() {
        await productService.all()
        this.selected = this.hasProduct ? 1 : null
        await this.$store.dispatch('loadMe')
        // this.products = this.products.ticket
        //
        // if (this.isNewbie) this.products = this.products.find(p => p.code === 'ticket_primary')
        // else this.products = this.products.find(p => p.code === 'ticket_free')
    },
}
</script>

<style scoped lang="scss">
.schedule-payment {
    .body {
        padding: 0 16px;
        margin-top: 12px;
    }
    .title {
        font-size: 16px;
        line-height: 26px;
        color: black;
        margin-bottom: 8px;
    }

    .header {
        color: black;
        font-size: 18px;
        text-align: center;
        line-height: 28px;
        margin: 24px 0 0 0;
        @include spoqa-f-bold;
    }

    .price-detail {
        .row {
            font-size: 16px;
            line-height: 26px;
            padding-bottom: 8px;
            @include between;

            &.under-line {
                border-bottom: dashed $grey-03;
            }

            .key {
                color: $grey-06;
            }
            .value {
                color: black;
            }
        }
    }

    .inactive {
        border: 1px solid $grey-03 !important;
        color: $grey-04 !important;
    }
    .selected {
        border: 1px solid $blue-primary;
        color: $blue-primary;
    }

    .grey-box {
        background: $grey-01;
        padding: 8px;
        font-size: 14px;
        border-radius: 12px;

        .user-profile {
            display: flex;
            align-items: center;
            color: black;
            font-size: 14px;
            line-height: 24px;

            img {
                width: 80px;
                height: 80px;
                border: 1px solid white;
                border-radius: 8px;
                margin-right: 14px;
            }
        }
    }
    .open-next-day {
        background-color: rgba(255, 61, 107, 0.07);
        border-radius: 8px;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        padding: 8px 12px;
        margin-top: 8px;
    }
}
</style>
